.TextWidgetEditorNoOverflow .ck-content {
    height: 100%;

    overflow-y: auto;
    /* TODO: Applying the font resize factor causes visual bugs for margins and overflowing content. 
            Remove this line after that is fixed.
    */
    overflow: hidden !important;
}

.TextWidgetEditorScroll .ck-content {
    height: 100%;
    overflow-y: auto;
}

.TextWidget_Container_no_padding .ck-content > :first-child {
    padding-top: 0 !important;
    margin-top: 0 !important;
}
